import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CategoryTile from '../../../Components/CategoryTile/CategoryTile'
import SubscribeBanner from '../../../Components/SubscribeBanner/SubscribeBanner'
import * as service from "./service";
import Slider from "react-slick";
import { getUser } from '../../../Redux/User/userSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { authenticate } from '../../../CommonService';
import Live from '../LivePage/Live';
const Home = () => {
    const [categories,setCategories]=useState([])
    const [loading,setLoading]=useState(true)
    const [language, setLanguage] = useState(39)
    const location = useLocation()
    useEffect(() => {
      window.scrollTo(0,0);
    service.getShowsByCategory().then((response)=>{

            setCategories(response?.data)
            setLoading(false)
        })
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        service.getShowsByCategory().then((response) => {
            setCategories(response?.data);
            setLoading(false);
        });

        // Only update language if explicitly set in navigation state
        if (location.state?.language) {
            setLanguage(location.state.language)
        } else {
            setLanguage(language)
        }
    }, [location.state?.language]);
    
  return (
    <>
    {
        loading?(

            <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
        ):(

    <div className='homeContainer'>
         {/* <Live home={true} language={language} /> */}
         <Live home={true} language={location.state?.language || 39} />
        <div className="wrapper">

            <div className="categoriesSection">

                {
                    categories?.map((item,key)=>{
                        if(item?.category_name!=="Magazines"&&item?.category_id!=99989){
                            return(

                                <CategoryTile
                                    key={key}
                                    title={item?.category_name}
                                    cardType={item?.category_name=="Latest News"?"buttonCard":item?.category_name=="Shows"?"descriptionCard":item?.category_name=="Featured"?"dateCard":"magazineCard"}
                                    shows={item?.shows}
                                    origin ="home"
                                />
                            )
                        }

                    })
                }
                <div>

                    {
                        categories?.map((item,key)=>{
                            if(item?.category_name==="Magazines"){
                                return(

                                    <CategoryTile
                                        key={key}
                                        title={"Magazines"}
                                        cardType={"magazineCard"}
                                        shows={item?.shows}
                                    />
                                )
                            }

                        })
                    }
                </div>
               
            </div>

                
        </div>
            <SubscribeBanner/>

            
    </div>
        )
    }
    </>
  )
}

export default Home